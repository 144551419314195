import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'

import { Block } from '@components/Block'

import style from '@styles/ContentBlock.module.scss'
import { BlockType, SectionType } from '@contentful/types'

interface Props {
  fields: {
    blocks: BlockType[]
    layout?: string
    spacing?: string
    backgroundColor?: string
  }
  section: SectionType
}

const ContentBlock = ({ fields: { blocks, layout, spacing, backgroundColor }, section }: Props) => {
  const layoutClass = (layout => {
    switch (layout) {
      case 'Full Width Side by Side':
        return style.containerFullWidthSideBySide
      case 'Side by Side':
        return style.containerSideBySide
      default:
        return null
    }
  })(layout)

  const spacingClass = (spacing => {
    switch (spacing) {
      case 'Extra Top':
        return style.containerExtraTopSpacing
      case 'Extra Bottom':
        return style.containerExtraBottomSpacing
      case 'Extra Top and Bottom':
        return style.containerExtraTopAndBottomSpacing
      case 'Top':
        return style.containerTopSpacing
      case 'Top and Extra Bottom':
        return style.containerTopAndExtraBottomSpacing
      case 'Half Top':
        return style.containerHalfTopSpacing
      case 'Half Bottom':
        return style.containerHalfBottomSpacing
      case 'No Bottom':
        return style.containerNoBottomSpacing
      default:
        return null
    }
  })(spacing)

  const backgroundColorClass = (color => {
    switch (color) {
      case 'Beige':
        return style.containerBeige
      case 'Lavender':
        return style.containerLavender
      case 'Light Blue':
        return style.containerLightBlue
      default:
        return null
    }
  })(backgroundColor)

  const getContentClass = (type: string) => {
    switch (type) {
      case 'grayBox':
        return style.contentGrayBox
      case 'imageBlock':
        return style.contentImage
      case 'reflectionBlock':
        return style.contentReflection
      case 'embeddedContent':
        return style.contentEmbeddedContent
      default:
        return null
    }
  }

  return (
    <div className={classNames(style.container, layoutClass, spacingClass, backgroundColorClass)}>
      {blocks.map(block => {
        const id = get(block, 'sys.id')
        const type = get(block, 'sys.contentType.sys.id')
        const contentClass = getContentClass(type)

        return (
          <div key={id} className={classNames(style.content, contentClass)}>
            <Block type={type} fields={block.fields} section={section} />
          </div>
        )
      })}
    </div>
  )
}

export { ContentBlock }
