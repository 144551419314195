import React from 'react'
import classNames from 'classnames'
import { get } from 'lodash'

import style from '@styles/TextCard.module.scss'
import { Block } from '@components/Block'
import { BlockType, SectionType } from '@contentful/types'

interface Props {
  fields: {
    block: BlockType
    color: string
    fullWidth: boolean
    hintText?: string
    spacing?: string
    title: string
  }
  section: SectionType
}

const TextCard = ({
  fields: { block, color, fullWidth, hintText, spacing, title },
  section,
}: Props) => {
  const type = get(block, 'sys.contentType.sys.id')

  const colorClass = (color => {
    switch (color) {
      case 'Teal':
        return style.containerTeal
      case 'Green':
        return style.containerGreen
      case 'Red':
        return style.containerRed
      case 'Teal Title Only':
        return style.titleTeal
      default:
        return null
    }
  })(color)

  const spacingClass = (spacing => {
    switch (spacing) {
      case 'Extra Bottom':
        return style.extraBottomSpacing
      default:
        return null
    }
  })(spacing)

  const widthClass = fullWidth ? null : style.narrowWidth

  return (
    <div className={classNames(style.container, colorClass, spacingClass, widthClass)}>
      <div className={style.title}>{title}</div>
      <div className={style.blockContainer}>
        <Block type={type} fields={block.fields} section={section} />
        {hintText && (
          <p className={style.hintText}>
            {hintText}
            <hr className={style.coloredLine} />
          </p>
        )}
      </div>
    </div>
  )
}

export { TextCard }
