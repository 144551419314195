import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import Head from 'next/head'
import axios from 'axios'
import classNames from 'classnames'
import { get } from 'lodash'
import { useRouter } from 'next/dist/client/router'

import 'url-search-params-polyfill'

import { Block } from '@components/Block'
import { LeftNavigation } from '@components/LeftNavigation'
import { Footer } from '@components/Footer'
import { TopBar } from '@components/TopBar'
import { PageCompletedTrigger } from '@components/PageCompletedTrigger'
import { QuizIllustrations } from '@components/QuizIllustrations'
import {
  getFooter,
  getNavigationBar,
  getPage,
  getPagePaths,
  getTraining,
} from '@contentful/contentfulService'
import {
  BlockType,
  FooterType,
  NavigationBarType,
  SectionType,
  TrainingType,
} from '@contentful/types'
import { getLocaleFromPath } from '@lib/i18n'
import {
  getNewSectionLabel,
  getNextPageTitle,
  getNextPageUrl,
  getPageSection,
} from '@contentful/page'

import style from '@styles/Page.module.scss'
import { useNavigateToSavePointOnLoad } from '@hooks/navigation'
import { useValidateUserToken } from '@hooks/useValidateUserToken'
import { setProgressQuery } from '@queries/setProgressQuery'

interface Props {
  title: string
  section: SectionType | null
  layout?: string
  blocks: BlockType[]
  navigationBar: NavigationBarType
  footer: FooterType
  locale: string
  pageId: string
  path: string
  training: TrainingType
}

export default function DynamicPage({
  blocks,
  footer,
  layout,
  locale,
  navigationBar,
  pageId,
  path,
  section,
  title,
  training,
}: Props) {
  const [loading, setLoading] = useState(true)
  const onLoad = useCallback(() => {
    setLoading(false)
  }, [setLoading])
  const sectionList = get(training, 'fields.sections', [])

  const { user, refetchUser } = useValidateUserToken(locale)
  const storedProgress = user?.progress

  const setPageCompleted = useCallback(
    async (pageId: string) => {
      if (!user || !user.progress) return

      await setProgressQuery({
        token: user.token,
        completed_pages: [...user.progress.completed_pages, pageId],
      })
      await refetchUser()
    },
    [refetchUser, user]
  )

  useNavigateToSavePointOnLoad({
    currentPageId: pageId,
    sectionList,
    onLoad,
    locale,
    storedProgress,
    token: user?.token,
  })

  const router = useRouter()

  const onLanguageChange = async (locale: string) => {
    if (user) {
      setProgressQuery({ token: user.token, locale })
    }
    const contentfulPageResponse = await axios(`/api/contentful/page/${pageId}?locale=${locale}`)
    const newPath = contentfulPageResponse.data.path
    router.replace(`${newPath}?restored=1`)
  }

  let pageTitle = title
  let sectionTitle = null

  if (section !== null) {
    sectionTitle = get(section, 'fields.title')
    pageTitle = sectionTitle === title ? title : `${sectionTitle}: ${title}`
  }

  const nextPageUrl = useMemo(() => getNextPageUrl(pageId, sectionList), [pageId, sectionList])
  const quizButtonName = useMemo(() => {
    if (getNextPageTitle(pageId, sectionList) === navigationBar.fields.titleForQuizPages) {
      return navigationBar.fields.titleForQuizPages
    }
  }, [navigationBar.fields.titleForQuizPages, pageId, sectionList])
  const newSectionName = useMemo(() => getNewSectionLabel(pageId, sectionList), [
    pageId,
    sectionList,
  ])

  const nextButtonLabelOverride = quizButtonName || newSectionName

  const footerCopyright = get(footer, 'fields.copyright')
  const footerLinks = get(footer, 'fields.links')

  const sectionColor = get(section, 'fields.color')
  const sectionColorClass = (color => {
    switch (color) {
      case 'Blue':
        return style.contentBlue
      case 'Orange':
        return style.contentOrange
      case 'Purple':
        return style.contentPurple
      case 'Teal':
        return style.contentTeal
      case 'Dark Teal':
        return style.contentDarkTeal
      default:
        return null
    }
  })(sectionColor)

  const quizLayout = 'Quiz'
  const isQuizLayout = layout === quizLayout

  const layoutClass = (layout => {
    switch (layout) {
      case quizLayout:
        return style.contentQuiz
      default:
        return null
    }
  })(layout)

  const pageBlocks = blocks === undefined ? [] : blocks

  let completedTrigger = useRef(<></>)
  const onPageCompleted = useCallback(() => setPageCompleted(pageId), [pageId, setPageCompleted])

  useEffect(() => {
    completedTrigger.current = <PageCompletedTrigger key={pageId} onCompleted={onPageCompleted} />
  }, [pageId, onPageCompleted])

  return (
    <div>
      {(loading || user === null) && <div className={style.loadingOverlay} />}
      <Head>
        <title>{pageTitle}</title>
        <link rel="icon" href="/favicon.ico" />
      </Head>

      <main className={style.main}>
        <LeftNavigation
          completedPageIds={storedProgress?.completed_pages}
          training={training}
          currentSection={section}
          currentPageId={pageId}
          currentPath={path}
          locale={locale}
          onLanguageChange={onLanguageChange}
        />
        <TopBar
          title={title}
          section={sectionTitle}
          navigationBarEntry={navigationBar}
          nextButtonLabelOverride={nextButtonLabelOverride}
          nextPageUrl={nextPageUrl}
        />
        <div className={style.contentContainer}>
          <div className={classNames(style.content, sectionColorClass, layoutClass)}>
            {pageBlocks.map(block => {
              const id = get(block, 'sys.id')
              const type = get(block, 'sys.contentType.sys.id')

              return (
                <Block
                  key={id}
                  type={type}
                  fields={block.fields}
                  section={section}
                  nextPageUrl={nextPageUrl}
                  onPageCompleted={onPageCompleted}
                  layout={layout}
                />
              )
            })}
            {isQuizLayout && <QuizIllustrations inline={false} />}
          </div>
        </div>
        {completedTrigger.current}
        <Footer copyright={footerCopyright} links={footerLinks} />
      </main>
    </div>
  )
}

export async function getStaticPaths() {
  return {
    paths: await getPagePaths(),
    fallback: false,
  }
}

interface Params {
  params: {
    slug: string[]
  }
}

const defaultParams = { slug: [''] }
export async function getStaticProps({
  params: { slug } = defaultParams,
}: Params): Promise<{ props: Props }> {
  const path = `/${slug.join('/')}`
  const locale = getLocaleFromPath(path)

  const page = await getPage(path, locale)
  const navigationBar = await getNavigationBar(locale)
  const footer = await getFooter(locale)
  const training = await getTraining(locale)

  const pageId = get(page, 'sys.id')
  const section = getPageSection(pageId, training.fields.sections)

  return {
    props: { ...page.fields, footer, navigationBar, section, locale, pageId, path, training },
  }
}
